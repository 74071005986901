// src/hooks/useVerifyAccess.js
import { useState, useEffect, useCallback } from "react";
import { useAuthContext } from "../contexts/AuthContext";
import { useCompanyContext } from "../contexts/CompanyContext";

const useVerifyAccess = () => {
  const { token } = useAuthContext();
  const [hasAccess, setHasAccess] = useState(false);
  const [hasDemoAccess, setHasDemoAccess] = useState(false);
  const [hasAccessIsLoading, setHasAccessIsLoading] = useState(true);
  const [hasAccessError, setHasAccessError] = useState(null);
  const { activeCompanyId } = useCompanyContext();

  const verifyAccess = useCallback(async () => {
    if (!token || !activeCompanyId) {
      setHasAccess(false);
      setHasAccessIsLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/me/company/verify-access?company_id=${activeCompanyId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setHasAccess(data.hasAccess);
      setHasDemoAccess(data.hasDemoAccess);
      setHasAccessError(null);
    } catch (err) {
      setHasAccessError(err.message);
      setHasAccess(false);
    } finally {
      setHasAccessIsLoading(false);
    }
  }, [token, activeCompanyId]);

  useEffect(() => {
    verifyAccess();
  }, [verifyAccess]);

  return {
    hasAccess,
    hasDemoAccess,
    hasAccessIsLoading,
    hasAccessError,
    refetch: verifyAccess,
  };
};

export default useVerifyAccess;
